<template>
  <div class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="true"
          :showSettingBtn="false"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="onClickCloseButton"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">CS Date</label>
            <input type="date" class="form-control" :value="formData.cs_date" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">CS No</label>
            <input type="text" class="form-control" :value="formData.cs_number" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Requester</label>
            <input type="text" class="form-control" :value="user" readonly>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Requisition No</label>
            <input type="text" class="form-control" :value="formData.requisition_number" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Requisition Date</label>
            <input type="date" class="form-control" :value="formData.requisition_date" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Department</label>
            <input type="text" class="form-control" :value="department" readonly>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Project</label>
            <input type="text" class="form-control" :value="project" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Business</label>
            <input type="text" class="form-control" :value="business" readonly>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="px-2">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a :class="{'active' : formData.has_item_detail, 'disabled' : !formData.has_item_detail}" class="nav-link"
             data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
        </li>
        <li class="nav-item">
          <a :class="{'active' : !formData.has_item_detail, 'disabled' : formData.has_item_detail}" class="nav-link"
             data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
        </li>
      </ul>
    </div>

    <div class="px-3">
      <template :key="index" v-for="(data, index) in accountsDetails">
        <ItemDetails
            class="mt-3"
            :index="index"
            :data="data"
            :products="products"
            :accountHeads="accountHeads"
            :vatRate="vatRate"
            :isItem="isItem"
        />

        <div class="mt-1">
          <template :key="index" v-for="(supplier, index) in data.suppliers">
            <button @click="openSupplierModal(supplier, false, data.account_head)" class="btn btn-primary mr-5">
              {{ supplier.contact_profile.full_name }}
            </button>
          </template>
        </div>
        <div class="mt-1 text-right">
          <button
            @click="openSupplierModal(data)" class="btn btn-primary"
            :disabled="! shouldSave"
          >
            Add Supplier
          </button>
        </div>
      </template>

      <template :key="index" v-for="(data, index) in itemsDetails">
        <ItemDetails
            class="mt-3"
            :index="index"
            :data="data"
            :products="products"
            :accountHeads="accountHeads"
            :vatRate="vatRate"
            :isItem="isItem"
        />
        <div class="mt-1">
          <template :key="index" v-for="(supplier, index) in data.suppliers">
            <button
              @click="openSupplierModal(supplier, false, data.product)"
              class="btn btn-primary mr-5"
              :disabled="! shouldSave"
            >
              {{ supplier.contact_profile.full_name }}
            </button>
          </template>
        </div>
        <div class="mt-1 text-right">
          <button
            @click="openSupplierModal(data)"
            class="btn btn-primary"
            :disabled="! shouldSave"
          >
            Add Supplier
          </button>
        </div>
      </template>
    </div>

    <div class="px-2 mt-3 mb-3">
      <div class="row mt-3 px-2">
        <div class="col-md-7 row justify-content-center">

          <div class="col-lg-7 col-12 text-center border-dashed">
            <div class="d-flex flex-wrap justify-content-between align-middle">
              <BlobImageViewer
                  v-for="(file, index) in formData.additional_images"
                  :file="file"
                  :key="index"
                  :index="index"
              >
                <template v-slot:handler>
                  <img
                      class="position-absolute cursor-pointer top-0"
                      width="15"
                      src="/app-assets/images/icons/trash.svg"
                      alt=""
                  >
                </template>
              </BlobImageViewer>
            </div>
            <div class="pt-2">
              <div>
                <!--TODO: Setup bind functions and remove hidden attribute from input-->
                <input hidden>
                <p>Drop the files here ...</p>
                <p>Drag 'n' drop some additional images</p>
              </div>
              <button @click.prevent="open" class="btn btn-outline-primary">open</button>
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <div class="row mt-1">
            <div class="col-md-4 align-middle">SubTotal :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="subTotal" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4 align-middle">VAT :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="totalVAT" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4 align-middle">Total :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="total" readonly>
            </div>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="mb-2">
            <label for="note" class="form-label fw-bold">Terms & Condition</label>
            <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2"
                      id="description"></textarea>
          </div>
        </div>
      </div>

      <div class="row mb-1 d-flex justify-content-end">
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              v-if="! isCsCreate"
              style="min-width: 64px;margin-top: 1rem;"
              @click="saveCS()"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
              :disabled="! shouldSave"
          >Save
          </button>
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
            @click="$refs.viewApprovalMatrix.updateShowApprovalMatrix(true)"
            style="min-width: 64px;margin-top: 1rem;"
            class="btn btn-warning waves-effect waves-float waves-light form-control"
            v-if="formData.status && formData.status !== 'pending'"
          >
            Show More
          </button>
        </div>
      </div>
      <SupplierAddModal
          :key="supplierKey"
          :isProduct="isItem"
          :suppliersData="suppliersData"
          :vatRate="vatRate"
          ref="supplier"
          @onSubmitSupplierForm="handleSupplierSubmit"
          @onCloseSupplierModal="handleSupplierModalClose"
      />
    </div>
  </div>

  <view-approval-matrix-details
    :workflowDetails="workflowDetails"
    :approvalLogs="csEditData.workflow_approval_logs ?? []"
    ref="viewApprovalMatrix"
  />
  <GlobalLoader/>
</template>

<style>
hr {
  margin: 1rem 0;
  color: #403d4452 !important;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

.title-background {
  background: #DBE7F2
}
</style>

<script setup>
import TitleButton                               from '@/components/atom/TitleButton'
import {inject, ref, onMounted, computed, watch} from 'vue';
import {useRoute, useRouter}                     from "vue-router";
import {useStore}                                from 'vuex';
import BlobImageViewer                           from '@/components/atom/BlobImageViewer'
import handleRequisitions                        from "@/services/modules/procurement/requisition";
import handlePurchase                            from '@/services/modules/purchase'
import SupplierAddModal
                                                 from '@/components/molecule/procurement/vendor-selection/comparative-statement/SupplierAddModal.vue'
import ItemDetails
                                                 from '@/components/molecule/procurement/vendor-selection/comparative-statement/ItemDetails.vue'
import {vatRate}                                 from '@/data/inventory.js'
import ViewApprovalMatrixDetails from "@/components/molecule/approval-matrix/ViewApprovalMatrixDetails.vue";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

const router      = useRouter()
const route       = useRoute()
const supplier    = ref(null)
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const store       = useStore()
const {fetchWorkflowFromPageId} = handleWorkflow()

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {
        fetchSingleRequisition,
        fetchCSNumber,
        createNewCS,
        fetchSingleCS,
        updateSupplierCS,
        updateCS
      }                                             = handleRequisitions();

const companyId   = computed(() => {
  return route.params.companyId
})
let headerTitle   = ref('Create CS')
let loader        = ref(false)
let productLoader = ref(false)
let products      = ref([])
let accountHeads  = ref([])
let homeContent   = ref([])
let requisition   = ref({})
let user          = ref(null)
let department    = ref(null)
let project       = ref(null)
let business      = ref(null)
let supplierKey   = ref(1)
let suppliersData = ref({})
let csEditData    = ref({})
const workflowDetails = ref({});
const viewApprovalMatrix = ref(null);
let csData        = {
  general: [],
}
const isCsCreate  = ref(true)
let formData      = ref({
  cs_number         : null,
  cs_date           : null,
  requisition_id    : null,
  requisition_date  : null,
  requisition_number: null,
  department_id     : null,
  project_id        : null,
  project_budget_ref: null,
  business_id       : null,
  subtotal_amount   : 0.00,
  vat_amount        : 0.00,
  total_amount      : 0.00,
  has_item_detail   : true,
  status            : 'pending',
  description       : '',
  item_details      : [
    {
      product_id  : null,
      quantity    : null,
      rate        : null,
      amount      : null,
      vat         : null,
      vat_amount  : null,
      total_amount: null,
      description : null,
      suppliers   : []
    }
  ],
  account_details   : [
    {
      account_head_id: null,
      amount         : null,
      vat            : null,
      vat_amount     : null,
      total_amount   : null,
      description    : null,
      suppliers      : []
    }
  ],
})

function onClickAddNewButton() {
  alert('Add New Button clicked');
}

const shouldSave = computed( () => {
  if(! formData.value.status) {
    return true;
  }

  return ['pending', 'returned'].includes(formData.value.status);
})

function onClickCloseButton() {
  delete route.query.requisitionId;
  delete route.query.csId;

  router.push({
    name: isCsCreate.value ? 'requisition-list-allocated-cs' : 'cs-list',
    params: route.params,
    query: route.query
  })
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function handleSupplierSubmit(data) {
  suppliersData.value = {};
  suppliersData.value = data;
  if (isCsCreate.value) {
    csData.company_id         = companyId.value;
    csData.department_id      = formData.value.department_id;
    csData.project_id         = formData.value.project_id;
    csData.business_id        = formData.value.business_id;
    csData.requisition_id     = formData.value.requisition_id;
    csData.cs_number          = formData.value.cs_number;
    csData.cs_date            = formData.value.cs_date;
    csData.requisition_number = formData.value.requisition_number;
    csData.requisition_date   = formData.value.requisition_date;
    csData.status             = formData.value.status;
    csData.description        = formData.value.description;
    if (suppliersData.value !== null) {
      csData.general.push(suppliersData.value);
      csData.general = JSON.stringify(csData.general);
    } else {
      csData.general = null;
    }
    createCS();
  } else {
    updateSupplier();
  }
}

function saveCS() {
  if (isCsCreate.value) {
    handleSupplierSubmit(null);
  } else {
    updateCSData();
  }
}

function updateCSData() {
  let data         = {};
  data.status = formData.value.status;
  data.description = formData.value.description;
  updateCS(data, route.query.csId, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message)
          navigateToListPage();
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false
        console.log(err)
      })
}

function createCS(redirect = true) {
  createNewCS(csData, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message)
          if (redirect){
            window.location.replace(window.location.origin + route.fullPath + res.data.cs_id);
          }
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false
        console.log(err)
      })
}

function updateSupplier() {
  loader.value = true;
  suppliersData.value.status = csEditData.value.status;
  updateSupplierCS(suppliersData.value, getQuery())
      .then(res => {
        if (res.status) {
          loader.value  = false;
          const fetchCS = fetchSingleCS(route.query.csId, getQuery());
          fetchCS.then(res2 => {
            showSuccess(res.message)
            csEditData.value = {};
            csEditData.value = res2.data;
            setFormData();
            supplier.value.toggleModal();
            loader.value = false;
            supplierKey.value += 1;
          });
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false
        console.log(err)
      })
}

function openSupplierModal(item, isCreate = true, product = {}) {
  resetSupplierData();
  if (isCreate) {
    suppliersData.value.isCreate                     = true;
    suppliersData.value.id                           = null;
    suppliersData.value.cs_master_id                 = isCsCreate.value ? null : route.query.csId;
    suppliersData.value.requisition_id               = route.query.requisitionId;
    suppliersData.value.item_specification           = null;
    suppliersData.value.requisition_quantity         = item.quantity;
    suppliersData.value.related_requisition_table_id = item.id;
    suppliersData.value.item_name                    = isItem.value
                                                       ? item.product.name
                                                       : item.account_head.name;
  } else {
    suppliersData.value.isCreate                     = false;
    suppliersData.value.id                           = item.id;
    suppliersData.value.cs_master_id                 = item.cs_master_id;
    suppliersData.value.requisition_id               = item.requisition_id;
    suppliersData.value.supplier_id                  = item.contact_profile_id;
    suppliersData.value.item_specification           = item.item_specification;
    suppliersData.value.requisition_quantity         = item.requisition_quantity;
    suppliersData.value.related_requisition_table_id = item.related_requisition_table_id;
    suppliersData.value.item_name                    = product.name;
  }

  suppliersData.value.cs_date      = formData.value.cs_date;
  suppliersData.value.cs_number    = formData.value.cs_number;
  suppliersData.value.quantity     = isItem.value ? item.quantity : 0;
  suppliersData.value.rate         = isItem.value ? item.rate : 0.00;
  suppliersData.value.amount       = item.amount;
  suppliersData.value.vat          = item.vat;
  suppliersData.value.vat_amount   = item.vat_amount;
  suppliersData.value.total_amount = item.total_amount;
  suppliersData.value.description  = item.description;

  supplier.value.toggleModal();
  supplier.value.loadSuppliers();
}

function resetSupplierData() {
  suppliersData.value.isCreate                     = true;
  suppliersData.value.id                           = null;
  suppliersData.value.cs_master_id                 = null;
  suppliersData.value.requisition_id               = null;
  suppliersData.value.supplier_id                  = null;
  suppliersData.value.item_specification           = null;
  suppliersData.value.related_requisition_table_id = null;
  suppliersData.value.item_name                    = null;
  suppliersData.value.cs_date                      = null;
  suppliersData.value.cs_number                    = null;
  suppliersData.value.requisition_quantity         = 0;
  suppliersData.value.quantity                     = 0;
  suppliersData.value.rate                         = null;
  suppliersData.value.amount                       = 0.00;
  suppliersData.value.vat                          = 0;
  suppliersData.value.vat_amount                   = 0.00;
  suppliersData.value.total_amount                 = 0.00;
  suppliersData.value.description                  = null;
}

function handleSupplierModalClose() {
  resetSupplierData();
}

function getQuery() {
  return `?company_id=${route.params.companyId}&only_cs=1`;
}

function navigateToListPage() {
  delete route.query.requisitionId;
  delete route.query.csId;

  router.push({name: `cs-list`, params: route.params, query: route.query});
}

function setFormData() {
  if (isCsCreate.value) {
    formData.value.cs_date     = new Date().toISOString().split('T')[0];
    formData.value.description = requisition.value.description;
  } else {
    headerTitle.value          = 'Update CS';
    formData.value.cs_number   = csEditData.value.cs_number;
    formData.value.cs_date     = new Date(csEditData.value.cs_date).toISOString().split('T')[0];
    formData.value.description = csEditData.value.description;
    formData.value.status      = csEditData.value.status;
  }
  formData.value.requisition_id     = requisition.value.id;
  user.value                        = requisition.value.user.name;
  department.value                  = requisition.value.department?.name;
  project.value                     = requisition.value.project?.name;
  business.value                    = requisition.value.business?.name;
  formData.value.requisition_number = requisition.value.requisition_number;
  formData.value.department_id      = requisition.value.department_id;
  formData.value.project_id         = requisition.value.project_id;
  formData.value.business_id        = requisition.value.business_id;
  formData.value.requisition_date   = new Date(requisition.value.date).toISOString().split('T')[0];
  formData.value.company_id         = requisition.value.company_id;
  formData.value.subtotal_amount    = requisition.value.subtotal_amount;
  formData.value.vat_amount         = requisition.value.vat_amount;
  formData.value.total_amount       = requisition.value.total_amount;
  formData.value.has_item_detail    = requisition.value.has_item_detail;
  formData.value.item_details       = requisition.value.general;
  formData.value.account_details    = requisition.value.account;

  if (formData.value.has_item_detail) {
    formData.value.item_details.forEach((item) => {
      if (!isCsCreate.value) {
        if (csEditData.value.general.length > 0) {
          item.suppliers = [];
          csEditData.value.general.forEach((cs_item) => {
            if ((cs_item.related_requisition_table_id == item.id) && cs_item.is_product == 1) {
              cs_item.requisition_quantity = item.quantity;
              item.suppliers.push(cs_item);
            }
          });
        }
      } else {
        item.suppliers = [];
      }
    });
  } else {
    formData.value.account_details.forEach((item) => {
      if (!isCsCreate.value) {
        if (csEditData.value.general.length > 0) {
          item.suppliers = [];
          csEditData.value.general.forEach((cs_item) => {
            if ((cs_item.related_requisition_table_id == item.id) && cs_item.is_product == 0) {
              item.suppliers.push(cs_item);
            }
          });
        }
      } else {
        item.suppliers = [];
      }
    });
  }
}

function onMountedReload() {
  loader.value           = true
  const csNumberRes      = fetchCSNumber(getQuery());
  const productRes       = fetchProductList(getQuery())
  const accountHeadRes   = fetchAccountHead(getQuery())
  const fetchRequisition = fetchSingleRequisition(route.query.requisitionId, getQuery());
  const workflowQuery = getQuery() + `&approval_list_page_id=${route.params.pageId}`;

  Promise.all([
    productRes.then(res => {
      if (res.data) products.value = res.data
    }),
    accountHeadRes.then(res => {
      if (res.data) accountHeads.value = res.data
    }),
    fetchRequisition.then(res => {
      requisition.value = res.data;
      if (route.query.csId !== '') {
        isCsCreate.value    = false;
        const fetchCS = fetchSingleCS(route.query.csId, getQuery());
        fetchCS.then(res2 => {
          csEditData.value = res2.data;
          setFormData();
        });
      } else {
        csNumberRes.then(res3 => {
          formData.value.cs_number = res3;
        });
        setFormData();
      }
    }),
    fetchWorkflowFromPageId(workflowQuery).then(res => {
      if(res.data) workflowDetails.value = res.data
    })
  ])
      .then(() => {
        loader.value = false
      })
      .catch((err) => {
        loader.value = false
      })
}

const itemsDetails = computed(() => {
  return formData.value.item_details
})
const accountsDetails = computed(() => {
  return formData.value.account_details
})
const isItem = computed(() => {
  return !!formData.value.has_item_detail
})
const subTotal = computed(() => {
  let subTotal = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      subTotal += parseInt(item.quantity * item.rate)
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.amount) {
        subTotal += parseInt(item.amount)
      }
    })
  }

  return subTotal
})
const totalVAT = computed(() => {
  let vat = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if (item.vat) {
        vat += parseInt((item.vat / 100) * item.quantity * item.rate)
      }
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.vat) {
        vat += parseInt((item.vat / 100) * item.amount)
      }
    })
  }

  return vat.toFixed(2)
})
const total = computed(() => {
  return parseInt(subTotal.value) + parseInt(totalVAT.value)
})

onMounted(async () => {
  onMountedReload();
})
</script>
