<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div v-if="isItem" class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Product</label>
                        <vField
                            v-model="data.product_id"
                            name="product_id"
                            type="text" 
                            class="form-control d-none" 
                        />
                        <v-select
                            placeholder="Select Product"
                            v-model="data.product_id"
                            :options="products" 
                            label="text"
                            :reduce="name => name.id"
                            disabled
                        />
                    </div>
                </div>
                <div v-else class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Account Heads</label>
                        <vField
                            v-model="data.account_head_id"
                            name="product_id"
                            type="text" 
                            class="form-control d-none" 
                        />
                        <v-select
                            placeholder="Select Head"
                            v-model="data.account_head_id"
                            :options="accountHeads" 
                            label="name"
                            :reduce="name => name.id"
                            disabled
                        />
                    </div>
                </div>

                <template v-if="isItem">
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="rate">Rate</label>
                            <vField
                                v-model="data.rate"
                                name="rate"
                                type="number" 
                                class="form-control text-right"
                                readonly
                            />
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="quantity">Quantity</label>
                            <vField
                                v-model="data.quantity"
                                name="quantity"
                                type="number" 
                                class="form-control text-right"
                                readonly
                            />
                        </div>
                    </div>
                </template>

                <div v-if="isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            readonly
                            v-model="amount"
                            name="amount"
                            type="number" 
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <div v-else class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            v-model="data.amount"
                            name="amount"
                            type="number" 
                            class="form-control text-right"
                            readonly
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat">VAT Rate</label>
                        <vField
                            v-model="data.vat"
                            id="vat"
                            as="select"
                            name="vat"
                            class="form-select text-right"
                            disabled
                        >
                            <option 
                                v-for="(vat, i) in vatRate"
                                :value="vat.value"
                                :key="i"
                            >
                                {{vat.label}}
                            </option>
                        </vField>
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat_amount">Vat amount</label>
                        <vField
                            v-model="vatAmount"
                            readonly
                            name="vat_amount"
                            type="number" 
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Total Amount</label>
                        <vField
                            v-model="totalAmount"
                            readonly
                            name="total_amount"
                            type="number" 
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <div class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="description">Description</label>
                         <vField
                            style="height: 38px"
                            as="textarea"
                            name="description"
                            type="number" 
                            class="form-control"
                            readonly
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref,computed } from "@vue/runtime-core"

let accountEditable = ref(0)

const pr = defineProps({
  title: String,
  data: Object,
  isItem: Boolean,
  index: Number,
  vatRate: Array,
  products: Array,
  accountHeads: Array
})

const amount = computed(() => {
    if(pr.isItem) {
        return pr.data.rate * pr.data.quantity
    }
    if(!pr.isItem) {
        return pr.data.amount
    }
})
const vatAmount = computed(() => {
    if(pr.data.vat && pr.isItem) {
        return (pr.data.vat/100 * amount.value).toFixed(2)
    } else {
        return pr.data.vat/100 * pr.data.amount
    }
    return 0
})

const totalAmount = computed(() => {
    if(pr.data.vat == 0) {
        return parseInt(amount.value)
    }
    if(pr.isItem) {
        return parseInt(amount.value) + parseInt(vatAmount.value)
    }
    return parseInt(pr.data.amount) + parseInt(vatAmount.value)
})

</script>


<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>

