<template>
  <div
      v-if="isOpen"
      class="modal fade show m-2"
      aria-labelledby="addNewCardTitle"
      style="display: block; background: #1c1d1c33"
  >
    <div class="width-95per bg-white mx-auto">
      <vForm @submit="submit" :validation-schema="schema">
        <div class="card">
          <div style="background: #DBE7F2" class="p-2">
            <TitleButton
                :showBtn="true"
                :showSettingBtn="false"
                :showAddNew="false"
                :title="headerTitle"
                @onClickSettingButton="onClickSettingButton"
                @onClickCloseButton="toggleModal()"
            />

            <div class="row mt-2">
              <div class="col-12 col-sm-6 col-md-6">
                <label class="align-middle">Supplier</label>
                <vField v-model="formData.supplier_id" class="d-none" name="supplier_id"/>
                <v-select
                    placeholder="Select Supplier"
                    v-model="formData.supplier_id"
                    :options="contacts"
                    label="name"
                    :reduce="name => name.id"
                />
                <ErrorMessage
                    name="supplier_id"
                    class="invalid-feedback d-block qcont"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-6">
                <label class="align-middle">CS Number</label>
                <input type="text" class="form-control" :value="formData.cs_number" readonly>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 col-sm-6 col-md-6">
                <label class="align-middle">Item Name</label>
                <input type="text" class="form-control" :value="formData.item_name" readonly>
              </div>
              <div class="col-12 col-sm-6 col-md-6">
                <label class="align-middle">CS Date</label>
                <input type="date" class="form-control" :value="formData.cs_date" readonly>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="px-3">
            <div style="border-radius: 8px" class="row journal-line">
              <div class="col-12 d-flex product-details-border position-relative pe-0">
                <div class="row w-100 pe-lg-0 me-1 py-2">
                  <div class="col-md-4 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="item_specification">Item Specification/ Details</label>
                      <vField
                          v-model="formData.item_specification"
                          style="height: 38px"
                          as="textarea"
                          name="item_specification"
                          class="form-control"
                      />
                      <ErrorMessage
                          name="item_specification"
                          class="invalid-feedback d-block qcont"
                      />
                    </div>
                  </div>

                  <template v-if="isProduct">
                    <div class="col-md-2 col-12">
                      <div class="mb-1">
                        <label class="form-label" for="rate">Rate</label>
                        <vField
                            v-model="formData.rate"
                            name="rate"
                            type="number"
                            class="form-control text-right"
                        />
                        <ErrorMessage
                            name="rate"
                            class="invalid-feedback d-block qcont"
                        />
                      </div>
                    </div>
                    <div class="col-md-2 col-12">
                      <div class="mb-1">
                        <label class="form-label" for="quantity">Quantity</label>
                        <vField
                            v-model="formData.quantity"
                            name="quantity"
                            type="number"
                            class="form-control text-right"
                            @change="onChangeQty"
                        />
                        <ErrorMessage
                            name="quantity"
                            class="invalid-feedback d-block qcont"
                        />
                      </div>
                    </div>
                  </template>

                  <div v-if="isProduct" class="col-md-2 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="amount">Amount</label>
                      <vField
                          v-model="amount"
                          name="amount"
                          type="number"
                          class="form-control text-right"
                          readonly
                      />
                    </div>
                  </div>
                  <div v-else class="col-md-2 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="amount">Amount</label>
                      <vField
                          v-model="formData.amount"
                          name="amount"
                          type="number"
                          class="form-control text-right"
                      />
                      <ErrorMessage
                          name="amount"
                          class="invalid-feedback d-block qcont"
                      />
                    </div>
                  </div>

                  <div class="col-md-2 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="vat">VAT Rate</label>
                      <vField
                          v-model="formData.vat"
                          id="vat"
                          as="select"
                          name="vat"
                          class="form-select text-right"
                      >
                        <option
                            v-for="(vat, i) in vatRate"
                            :value="vat.value"
                            :key="i"
                        >
                          {{ vat.label }}
                        </option>
                      </vField>
                    </div>
                  </div>

                  <div class="col-md-2 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="vat_amount">Vat amount</label>
                      <vField
                          v-model="vatAmount"
                          readonly
                          name="vat_amount"
                          type="number"
                          class="form-control text-right"
                      />
                    </div>
                  </div>

                  <div class="col-md-2 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="total_amount">Total Amount</label>
                      <vField
                          v-model="totalAmount"
                          readonly
                          name="total_amount"
                          type="number"
                          class="form-control text-right"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="px-2 mt-3 mb-3">
            <div class="row mt-3 px-2">
              <div class="col-md-12 row justify-content-center">

                <div class="col-lg-7 col-12 text-center border-dashed">
                  <div class="d-flex flex-wrap justify-content-between align-middle">
                    <BlobImageViewer
                        v-for="(file, index) in formData.additional_images"
                        :file="file"
                        :key="index"
                        :index="index"
                    >
                      <template v-slot:handler>
                        <img
                            class="position-absolute cursor-pointer top-0"
                            width="15"
                            src="/app-assets/images/icons/trash.svg"
                            alt=""
                        >
                      </template>
                    </BlobImageViewer>
                  </div>
                  <div class="pt-2">
                    <div>
                      <!--TODO: Setup bind functions and remove hidden attribute from input-->
                      <input hidden>
                      <p>Drop the files here ...</p>
                      <p>Drag 'n' drop some additional images</p>
                    </div>
                    <button @click.prevent="open" class="btn btn-outline-primary">open</button>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="mb-2">
                  <label for="note" class="form-label fw-bold">Memo</label>
                  <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2"
                            id="description"></textarea>
                </div>
              </div>
            </div>

            <div class="row mb-1 d-flex justify-content-end">
              <div class="col-2">
                <button
                    :disabled="loading"
                    type="submit"
                    class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
                >
                  <div
                      v-if="loading"
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </vForm>
    </div>
  </div>
  <GlobalLoader/>
</template>

<script setup>
import TitleButton                                         from '@/components/atom/TitleButton'
import {ref, inject, reactive, onMounted, computed, watch} from 'vue'
import {useRouter, useRoute}                               from 'vue-router'
import handleContact                                       from '@/services/modules/contact'
import BlobImageViewer                                     from '@/components/atom/BlobImageViewer'

const router   = useRouter()
const route    = useRoute()
const supplier = ref(null)
const isOpen   = ref(false);
const loading  = ref(false);
const emit     = defineEmits([
  'onSubmitSupplierForm', 'onCloseSupplierModal'
])

const props = defineProps({
  suppliersData: Object,
  isProduct    : {
    type   : Boolean,
    default: true,
  },
  vatRate      : Array,
});

let formData         = ref({
  is_product        : true,
  supplier_id       : null,
  cs_number         : null,
  item_name         : null,
  cs_date           : null,
  item_specification: null,
  quantity          : 0,
  rate              : 0.00,
  amount            : 0.00,
  vat               : null,
  vat_amount        : 0.00,
  total_amount      : 0.00,
  description       : null,
});
let headerTitle      = ref('Add Suppliers CS');
let schema           = ref({});
const schema_product = {
  supplier_id       : "required",
  item_specification: "required|min:3|max:300",
  rate              : "required",
  quantity          : "required",
  amount            : "required"
};

const schema_account = {
  supplier_id       : "required",
  item_specification: "required|min:3|max:300",
  amount            : "required"
};

const showError   = inject('showError');
const showSuccess = inject('showSuccess');

const {fetchContactProfiles} = handleContact()

const companyId = computed(() => {
  return route.params.companyId
})

let loader   = ref(false)
let contacts = ref([])

const amount      = computed(() => {
  if (props.isProduct) {
    return formData.value.rate * formData.value.quantity
  }
  if (!props.isProduct) {
    return formData.value.amount
  }
})
const vatAmount   = computed(() => {
  if (formData.value.vat && props.isProduct) {
    return (formData.value.vat / 100 * amount.value).toFixed(2)
  } else {
    return (formData.value.vat / 100 * formData.value.amount).toFixed(2)
  }
  return 0
})
const totalAmount = computed(() => {
  if (formData.value.vat == 0) {
    return parseInt(amount.value)
  }
  if (props.isProduct) {
    return parseInt(amount.value) + parseInt(vatAmount.value)
  }
  return parseInt(formData.value.amount) + parseInt(vatAmount.value)
})

function onChangeQty() {
  if (formData.value.quantity > formData.value.requisition_quantity) {
    showError('Quantity must not greater than requisition quantity');
  }
}

function submit() {
  if (formData.value.is_product &&
      (formData.value.quantity > formData.value.requisition_quantity)
  ) {
    showError('Quantity must not greater than requisition quantity');
    return;
  }

  formData.value.amount       = amount.value;
  formData.value.vat_amount   = vatAmount.value;
  formData.value.total_amount = totalAmount.value;
  emit('onSubmitSupplierForm', formData.value);
}

function toggleModal() {
  isOpen.value = !isOpen.value;
  if (!isOpen.value) {
    resetForm();
    emit("onCloseSupplierModal");
  }
}

function resetForm() {
  formData.value.is_product           = true;
  formData.value.isCreate             = true;
  formData.value.id                   = null;
  formData.value.cs_master_id         = null;
  formData.value.supplier_id          = null;
  formData.value.cs_number            = null;
  formData.value.item_name            = null;
  formData.value.cs_date              = null;
  formData.value.item_specification   = null;
  formData.value.quantity             = 0;
  formData.value.requisition_quantity = 0;
  formData.value.rate                 = 0.00;
  formData.value.amount               = 0.00;
  formData.value.vat                  = null;
  formData.value.vat_amount           = 0.00;
  formData.value.total_amount         = 0.00;
  formData.value.description          = null;
}

function loadSuppliers() {
  loader.value       = true
  schema.value       = props.isProduct ? schema_product : schema_account;
  const companyQuery = `?company_id=${companyId.value}`
  const contactRes   = fetchContactProfiles(companyQuery)

  Promise.all([
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
  ])
      .then(() => {
        setData();
        loader.value = false;
      })
      .catch((err) => {
        loader.value = false
      })
}

function setData() {
  resetForm();
  let propData            = props.suppliersData;
  formData.value.isCreate = propData.isCreate;
  if (!formData.value.isCreate) {
    headerTitle.value = 'Update Suppliers CS';
  } else {
    headerTitle.value = 'Add Suppliers CS';
  }
  formData.value.id                           = propData.id;
  formData.value.cs_master_id                 = propData.cs_master_id;
  formData.value.requisition_id               = propData.requisition_id;
  formData.value.supplier_id                  = propData.supplier_id;
  formData.value.is_product                   = props.isProduct;
  formData.value.related_requisition_table_id = propData.related_requisition_table_id;
  formData.value.item_name                    = propData.item_name;
  formData.value.cs_date                      = propData.cs_date;
  formData.value.item_specification           = propData.item_specification;
  formData.value.cs_number                    = propData.cs_number;
  formData.value.quantity                     = propData.quantity;
  formData.value.requisition_quantity         = propData.requisition_quantity;
  formData.value.rate                         = propData.rate;
  formData.value.amount                       = props.isProduct ? amount.value : propData.amount;
  formData.value.vat                          = propData.vat;
  formData.value.vat_amount                   = props.isProduct ? vatAmount.value : propData.vat_amount;
  formData.value.total_amount                 = props.isProduct ? totalAmount.value : propData.total_amount;
  formData.value.description                  = propData.description;
}

onMounted(async () => {
  //
})

defineExpose({
  toggleModal,
  loadSuppliers
})
</script>
